import { createContext, useState } from 'react';

export const SpinwheelContext = createContext(null);

const fetchToken = (setToken) => async (userId) => {
  let response = await fetch('/api/spinwheel/requestToken', {
    method: 'POST',
    body: `userId=${userId}`
  })

  response = await response.json()
  setToken(response.token)
}

export default function SpinwheelProvider({ children }) {
  const [swToken, setSwToken] = useState('')

  const context = {
    swToken,
    fetchSwToken: fetchToken(setSwToken),
  }

  return (
    <SpinwheelContext.Provider value={context}>
      {children}
    </SpinwheelContext.Provider>
  )
}