import React from "react";

const config = {
  containerId: 'spinwheel-identity-connect',
  env: 'sandbox',
  onSuccess: (metadata) => {
    console.log('onSuccess', metadata);
  },
  onLoad: (metadata) => {
    console.log('onLoad', metadata);
  },
  onEvent: (metadata) => {
    console.log('onEvent', metadata);
  },
  dropinConfig: {
    module: 'identity-connect',
    token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGllbnRJZCI6IjczOGJlZmJmLTgxOTUtNDNhNS1hZjg0LWQzZjJjZjE4ZTcxMiIsInBhcnRuZXJJZCI6IjM4NDQ3YmIxLTEzNjYtNDFjZS1hODgwLWE2ZTU3MTQ2MzJiZiIsImV4dFVzZXJJZCI6IjM1ODlhMjRkLTAyMjgtNDdlNi1iNWFkLWU3YjM2NzNmMjk1NSIsInNwaW53aGVlbFVzZXJJZCI6IjExMDQ1OGIwLTI4YTItNDNhMS1hZjFmLTAwMDU1MDMxZDRhYyIsInRva2VuRXhwaXJ5SW5TZWMiOjYwMCwiaXNDZG5SZXF1ZXN0Ijp0cnVlLCJlbnYiOiJzYW5kYm94IiwiaXNEZWZhdWx0RXhwaXJ5VGltZSI6dHJ1ZSwiaWF0IjoxNzA2MzkyNTg0LCJleHAiOjE3MDYzOTMxODR9.n21_T9oH-R-znf-YgKT7Czb2ncc5F_Zfp-GKeO9wBfU',
  },
};

const spinwheel_handler = window.Spinwheel && window.Spinwheel.create(config);

export function Home() {
  React.useEffect(() => {
    spinwheel_handler.open();
  }, [])

  return (
    <div style={{width: '100%', height: '100%'}} id="spinwheel-identity-connect"></div>
  )
}