import { createContext, useEffect, useState } from 'react';
import { createClient } from '@supabase/supabase-js'

const supabase_url = 'https://fjqqatwuogcgueqlqudg.supabase.co'
const supabase_key = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImZqcXFhdHd1b2djZ3VlcWxxdWRnIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDYwMDQ0MTIsImV4cCI6MjAyMTU4MDQxMn0.ta38TdLeMAwMSoMe06MBlh0CDuxqYRHh6CIkLH6zw9U'
const supabase = createClient(supabase_url, supabase_key, {multiTab: false})

export const SupabaseContext = createContext(null);

export default function SupabaseProvider({ children }) {
  const [session, setSession] = useState(null)

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session: newSession } }) => {
      setSession(newSession)
    })
  }, [])

  useEffect(() => {
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, newSession) => {
      if (session?.accessToken !== newSession?.accessToken) {
        setSession(newSession)
      }
    })

    return () => subscription.unsubscribe()
  }, [session])

  const context = {
    supabase,
    session
  }

  return (
    <SupabaseContext.Provider value={context}>
      {children}
    </SupabaseContext.Provider>
  )
}