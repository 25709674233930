import './App.css'
import { useContext, useEffect } from 'react'
import { Auth } from '@supabase/auth-ui-react'
import { ThemeSupa } from '@supabase/auth-ui-shared'
import { Home } from './Home'
import { SpinwheelContext } from "./SpinwheelContext";
import { SupabaseContext } from "./SupabaseContext";

export default function App() {
  const { fetchSwToken } = useContext(SpinwheelContext)
  const { session, supabase } = useContext(SupabaseContext)

  // fetch spinwheel user token with userId
  useEffect(() => {
    if (session) fetchSwToken(session.user.id)
  }, [session])

  if (!session) {
    return (<Auth supabaseClient={supabase} appearance={{ theme: ThemeSupa }} />)
  }
  else {
    return (<Home />)
  }
}
