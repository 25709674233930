import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import SpinwheelProvider from './SpinwheelContext';
import SupabaseProvider from './SupabaseContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <SupabaseProvider>
      <SpinwheelProvider>
        <App />
      </SpinwheelProvider>
    </SupabaseProvider>
  </React.StrictMode>
);
